import React from "react"; // { useContext } // ,
import {
  BrowserRouter as Router,
  Route,
  Switch,
  Redirect,
} from "react-router-dom";

// import { ThemeContext } from "./contexts/ThemeContext";
import {
  Main,
  // BlogPage,
  // ProjectPage
} from "./pages";
import { BackToTop } from "./components";
import ScrollToTop from "./utils/ScrollToTop";
import "@fontsource/roboto"; // Defaults to weight 400
import "@fontsource/roboto/400.css"; // Specify weight
import "@fontsource/roboto/400-italic.css"; // Specify weight and style

import "./App.css";
import ScegliAbbonamento from "./pages/ScegliAbbonamento/ScegliAbbonamento";
// import Privacy from "./pages/Privacy/Privacy";

function App() {
  // const { theme } = useContext(ThemeContext);

  return (
    <div className="app">
      <Router>
        <ScrollToTop />
        <Switch>
          <Route path="/" exact component={Main} />
          <Route
            path="/scegli-abbonamento"
            exact
            component={ScegliAbbonamento}
          />
          {/* <Route
						path='/projects'
						exact
						component={ProjectPage}
					/> */}

          <Redirect to="/" />
        </Switch>
      </Router>
      <BackToTop />
    </div>
  );
}

export default App;
