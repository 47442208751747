import { Box, Button } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import { ThemeContext } from "../../contexts/ThemeContext";
import { useContext } from "react";
import { green } from "@mui/material/colors";

const PricingList = styled("ul")({
  margin: 0,
  padding: 0,
  listStyle: "none",
});

const tiers = [
  {
    title: "1  MESE",
    price: "35",
    description: [
      // "10 users included",
      // "2 GB of storage",
      // "Help center access",
      // "Email support",
    ],
    buttonText: "PAGA ORA",
    buttonVariant: "outlined",
    link: "/paga35.html",
  },
  {
    title: "6 MESI",
    subheader: "Most popular",
    price: "175",
    description: [
      "CON 1 MESE OMAGGIO",
      // "20 users included",
      // "10 GB of storage",
      // "Help center access",
      // "Priority email support",
    ],
    buttonText: "PAGA ORA",
    buttonVariant: "contained",
    link: "/paga175.html",
  },
  {
    title: "12 MESI",
    price: "315",
    description: [
      "CON 3 MESI OMAGGIO",
      // "30 GB of storage",
      // "Help center access",
      // "Phone & email support",
    ],
    buttonText: "PAGA ORA",
    buttonVariant: "outlined",
    link: "/paga315.html",
  },
];
export default function Pricing() {
  const { theme } = useContext(ThemeContext);
  return (
    <Container maxWidth="md" component="main">
      <Grid container spacing={5} alignItems="flex-end">
        {tiers.map((tier) => (
          <Grid
            item
            key={tier.title}
            xs={12}
            sm={tier.title === "Enterprise" ? 12 : 6}
            md={4}
          >
            <Card>
              <CardHeader
                title={tier.title}
                subheader={tier.subheader}
                titleTypographyProps={{ align: "center" }}
                action={tier.title === "Pro" ? <StarIcon /> : null}
                subheaderTypographyProps={{
                  align: "center",
                }}
                sx={{
                  backgroundColor: theme.primary30,
                }}
              />
              <CardContent>
                <Box
                  sx={{
                    display: "flex",
                    justifyContent: "center",
                    alignItems: "baseline",
                    mb: 2,
                  }}
                >
                  <Typography component="h2" variant="h3" color="text.primary">
                    €{tier.price}
                  </Typography>
                  <Typography variant="h6" color="text.secondary"></Typography>
                </Box>
                <PricingList>
                  {tier.description.map((line) => (
                    <Typography
                      component="li"
                      variant="subtitle1"
                      align="center"
                      key={line}
                    >
                      {line}
                    </Typography>
                  ))}
                </PricingList>
              </CardContent>
              <CardActions>
                <div className="flex flex-col w-full gap-1">
                  <Button
                    href={tier.link}
                    fullWidth
                    variant={tier.buttonVariant}
                    sx={{
                      backgroundColor: green[500],
                      color: theme.secondary,
                      ":hover": { backgroundColor: green[700] },
                    }}
                  >
                    {tier.buttonText}
                  </Button>
                  {/* <Button
                    href="https://forms.gle/omXabpXjXZDwyiJr5"
                    fullWidth
                    variant={tier.buttonVariant}
                    sx={{
                      backgroundColor: green[500],
                      color: theme.secondary,
                      ":hover": { backgroundColor: green[700] },
                    }}
                    className="hidden"
                  >
                    Rinnova
                  </Button> */}
                </div>
              </CardActions>
            </Card>
          </Grid>
        ))}
      </Grid>
    </Container>
  );
}
