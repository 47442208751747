import { useContext } from "react";

import { ThemeContext } from "../../contexts/ThemeContext";

// import Rinnovo from "./Rinnovo";

export default function PricingPage({ ComponentPrice }) {
  const { theme } = useContext(ThemeContext);
  return (
    <div id="pricing" style={{ backgroundColor: theme.secondary }}>
      <h1 style={{ textAlign: "center" }}>Scegli il tuo abbonamento</h1>
      <br />
      <br />
      <ComponentPrice />
      <br />
      <br />
      <p style={{ textAlign: "center" }}>
        Con la libertà di acquistare quando vuoi senza alcun vincolo Puoi pagare
        con Paypal, bonifico bancario o PostePay
      </p>
      <br />
      <h2 style={{ textAlign: "center" }}>
        * DOPO AVER EFFETTUATO IL PAGAMENTO INVIA UN MESSAGGIO WHATSAPP AL
        NUMERO 391 7617985 INDICANDO ISCRIZIONE + COGNOME E NOME PER ESSERE
        INSERITO NEL GRUPPO PRIVATO
      </h2>
      <br />
      {/* <h1 style={{ textAlign: "center" }}>Oppure Puoi rinnovarlo</h1>
      <br />
      <br /> */}
      {/* <Rinnovo /> */}
      <br />
      <h2 style={{ textAlign: "center" }}>
        * DOPO AVER EFFETTUATO IL PAGAMENTO INVIA UN MESSAGGIO WHATSAPP AL
        NUMERO 391 7617985 INDICANDO RINNOVO + COGNOME E NOME
      </h2>
      <br />
      <br />
    </div>
  );
}
