import PricingPage from "../../components/Pricing/PricingPage";
import Pricing from "../../components/Pricing/Pricing";
import { Footer } from "../../components";

export default function ScegliAbbonamento() {
  return (
    <div style={{ height: "100wh" }}>
      <PricingPage ComponentPrice={Pricing} />
      <Footer />
    </div>
  );
}
