import React, { useContext } from "react";
import { makeStyles } from "@material-ui/core/styles";
import Fade from "react-reveal/Fade";

import { ThemeContext } from "../../contexts/ThemeContext";

// import eduImgWhite from "../../assets/svg/education/eduImgWhite.svg";
// import eduImgBlack from "../../assets/svg/education/eduImgBlack.svg";
import "./Education.css";
import ReactWhatsapp from "react-whatsapp";
import WhatsAppIcon from "@mui/icons-material/WhatsApp";

function LastEducationCard({ id, institution, course, startYear, endYear }) {
  const { theme } = useContext(ThemeContext);

  const useStyles = makeStyles((t) => ({
    educationCard: {
      backgroundColor: theme.primary30,
      "&:hover": {
        backgroundColor: theme.primary50,
      },
    },
  }));

  const classes = useStyles();

  return (
    <Fade bottom>
      <div key={id} className={`education-card ${classes.educationCard}`}>
        <div
          className="educard-img"
          style={{ backgroundColor: theme.secondary }}
        >
          {/* <img src={theme.type === 'light' ? eduImgBlack : eduImgWhite} alt="" /> */}

          <ReactWhatsapp
            className="whatsapp cursor-pointer"
            number="+393517082473"
            message="nome e cognome richiesta per la settimana omaggio"
            element="a"
          >
            <WhatsAppIcon
              className="whatsapp"
              style={{ color: "#25d366", fontSize: "2.5rem" }}
            />{" "}
          </ReactWhatsapp>
        </div>
        <div className="education-details">
          <h6 style={{ color: theme.primary }}>OPPURE</h6>
          <ReactWhatsapp
            className="whatsapp cursor-pointer"
            number="+393517082473"
            message="nome e cognome richiesta per la settimana omaggio"
            element="a"
          >
            <button
              style={{
                backgroundColor: theme.primary,
                color: "white",
                border: "none",
                padding: "0.5rem 1rem",
                borderRadius: "0.5rem",
                cursor: "pointer",
              }}
            >
              <strong>CLICCA QUI</strong>
            </button>
          </ReactWhatsapp>
          <h5 style={{ color: theme.tertiary80 }}>{institution}</h5>
        </div>
      </div>
    </Fade>
  );
}

export default LastEducationCard;
