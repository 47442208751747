import React from "react";
import { Helmet } from "react-helmet";

import {
  Navbar,
  Footer,
  Landing,
  // About,
  // Skills,
  Testimonials,
  Education,
  // Experience,
  Contacts,
  // Projects,
  Services,
  // Achievement,
} from "../../components";
import { headerData } from "../../data/headerData";

import PricingPage from "../../components/Pricing/PricingPage";
import SinglePricer from "../../components/Pricing/SinglePricer";

function Main() {
  return (
    <div>
      <Helmet>
        <title>{headerData.name}</title>
      </Helmet>

      <Navbar />

      <Landing />
      {/* <TrafficBySite /> */}

      <Education />
      {/* <About /> */}
      <Services />
      <PricingPage ComponentPrice={SinglePricer} />
      {/* <Skills />
      <Experience />
      <Projects />
      <Achievement /> */}

      <Testimonials />
      {/* <Blog /> */}
      <Contacts />
      <Footer />
    </div>
  );
}

export default Main;
