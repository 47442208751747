export const socialsData = {
	github: "https://github.com/",
	facebook: "https://www.facebook.com/nadia.cacciottinaturopata/?locale=it_IT",
	linkedIn: "https://www.linkedin.com/in",
	instagram: "https://www.instagram.com/nadia.cacciotti/",
	codepen: "https://codepen.io/",
	twitter: "https://twitter.com/nadiacacciotti",
	reddit: "https://www.reddit.com/user/",
	blogger: "https://www.blogger.com/",
	medium: "https://medium.com/@",
	stackOverflow: "https://stackoverflow.com/users/",
	gitlab: "https://gitlab.com/",
	telegram: "https://t.me/nadiacacciotti",
	youtube: "https://www.youtube.com/@nadiacacciotti886",
	tikTok: "http://www.tiktok.com/@nadiacacciotti?lang=it-IT",
};
