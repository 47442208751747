import React, { useContext } from "react";
import { Button } from "@material-ui/core";
import { NavHashLink as NavLink } from "react-router-hash-link";
import { makeStyles } from "@material-ui/core/styles";

import "./Landing.css";
import { ThemeContext } from "../../contexts/ThemeContext";
import { headerData } from "../../data/headerData";
import { socialsData } from "../../data/socialsData";
// import CounterItem from "../Counter/CounterItem";
import { useCountUp } from "react-countup";
import Box from "@mui/material/Box";
// import Button from "@mui/material/Button";
// import Typography from "@mui/material/Typography";
import Modal from "@mui/material/Modal";

import {
	FaTelegram,
	FaTwitter,
	// FaLinkedin,
	// FaGithub,
	FaYoutube,
	// FaBlogger,
	FaFacebook,
	FaInstagram,
	FaTiktok,
} from "react-icons/fa";
const modalStyle = {
	position: "absolute",
	top: "50%",
	left: "50%",
	transform: "translate(-50%, -50%)",
	// width: "60%",
	bgcolor: "transparent",
	border: "2px solid transparent",
	boxShadow: 24,
	p: 4,
};

function Landing() {
	const { theme, drawerOpen } = useContext(ThemeContext);
	useCountUp({ ref: "experienceCounter", end: 40, duration: 6 });
	useCountUp({ ref: "InstagramFollowers", end: 200, duration: 6 });
	useCountUp({ ref: "feedbackCounter", end: 100, duration: 6 });
	useCountUp({ ref: "facebook", end: 500, duration: 6 });
	useCountUp({ ref: "twitter", end: 500, duration: 6 });
	useCountUp({ ref: "youtube", end: 500, duration: 6 });

	const [open, setOpen] = React.useState(false);
	const handleOpen = () => setOpen(true);
	const handleClose = () => setOpen(false);

	const useStyles = makeStyles((t) => ({
		resumeBtn: {
			color: theme.primary,
			borderRadius: "30px",
			textTransform: "inherit",
			textDecoration: "none",
			width: "150px",
			fontSize: "1rem",
			fontWeight: "500",
			height: "50px",
			fontFamily: "var(--primaryFont)",
			border: `3px solid ${theme.primary}`,
			transition: "100ms ease-out",
			"&:hover": {
				backgroundColor: theme.tertiary,
				color: theme.secondary,
				border: `3px solid ${theme.tertiary}`,
			},
			[t.breakpoints.down("sm")]: {
				width: "180px",
			},
		},
		contactBtn: {
			backgroundColor: theme.primary,
			color: theme.secondary,
			borderRadius: "30px",
			textTransform: "inherit",
			textDecoration: "none",
			width: "300px",
			height: "50px",
			fontSize: "1rem",
			fontWeight: "500",
			fontFamily: "var(--primaryFont)",
			border: `3px solid ${theme.primary}`,
			transition: "100ms ease-out",
			"&:hover": {
				backgroundColor: theme.primary30,
				color: theme.tertiary,
				border: `3px solid ${theme.tertiary}`,
			},
			[t.breakpoints.down("sm")]: {
				display: "none",
			},
		},
	}));

	const classes = useStyles();

	return (
		<div className='landing'>
			<div className='landing--container'>
				<div
					className='landing--container-left'
					style={{ backgroundColor: theme.primary }}>
					<div className='lcl--content'>
						{socialsData.tikTok && (
							<div>
								<a
									href={socialsData.tikTok}
									target='_blank'
									rel='noreferrer'>
									<FaTiktok
										className='landing--social'
										style={{ color: theme.secondary }}
										aria-label='LinkedIn'
									/>
								</a>
								{/* <CounterItem
									counter={<span id='experienceCounter' />}
									measurement='K'
								/> */}
							</div>
						)}
						{socialsData.facebook && (
							<div>
								<a
									href={socialsData.facebook}
									target='_blank'
									rel='noreferrer'>
									<FaFacebook
										className='landing--social'
										style={{ color: theme.secondary }}
										aria-label='GitHub'
									/>
								</a>
								{/* <CounterItem
									counter={<span id='twitter' />}
									measurement='K'
								/> */}
							</div>
						)}
						{socialsData.instagram && (
							<div>
								<a
									href={socialsData.instagram}
									target='_blank'
									rel='noreferrer'>
									<FaInstagram
										className='landing--social'
										style={{
											color: theme.secondary,
										}}
										aria-label='Blogger'
									/>
								</a>
								{/* <CounterItem
									counter={<span id='facebook' />}
									measurement='K'
								/> */}
							</div>
						)}
						{socialsData.twitter && (
							<div>
								<a
									href={socialsData.twitter}
									target='_blank'
									rel='noreferrer'>
									<FaTwitter
										className='landing--social'
										style={{ color: theme.secondary }}
										aria-label='Twitter'
									/>
								</a>
								{/* <CounterItem
									counter={<span id='feedbackCounter' />}
									measurement='K'
								/> */}
							</div>
						)}
						{socialsData.telegram && (
							<div>
								<a
									href={socialsData.telegram}
									target='_blank'
									rel='noreferrer'>
									<FaTelegram
										className='landing--social'
										style={{ color: theme.secondary }}
										aria-label='Twitter'
									/>
								</a>
								{/* <CounterItem
									counter={<span id='feedbackCounter' />}
									measurement='K'
								/> */}
							</div>
						)}
						{socialsData.youtube && (
							<div>
								<a
									href={socialsData.youtube}
									target='_blank'
									rel='noreferrer'>
									<FaYoutube
										className='landing--social'
										style={{ color: theme.secondary }}
										aria-label='YouTube'
									/>
								</a>
								{/* <CounterItem
									counter={<span id='youtube' />}
									measurement='K'
								/> */}
							</div>
						)}
					</div>
				</div>
				<img
					onClick={handleOpen}
					src={headerData.image}
					alt=''
					className='landing--img'
					style={{
						opacity: `${drawerOpen ? "0" : "1"}`,
						borderColor: theme.secondary,
					}}
				/>
				<div
					className='landing--container-right'
					style={{ backgroundColor: theme.secondary }}>
					<div
						className='lcr--content'
						style={{ color: theme.tertiary }}>
						<h6>{headerData.title}</h6>
						<h1>{headerData.name}</h1>
						<p>{headerData.desciption}</p>

						<div className='lcr-buttonContainer'>
							{/* {headerData.resumePdf && (
                                <a
                                    href={headerData.resumePdf}
                                    download='resume'
                                    target='_blank'
                                    rel='noreferrer'
                                >
                                    <Button className={classes.resumeBtn}>
                                        Download CV
                                    </Button>
                                </a>
                            )} */}
							<NavLink
								to='/#metodo'
								smooth={true}
								spy='true'
								duration={2000}>
								<Button className={classes.contactBtn}>
									Prova Gratis il mio Metodo!
								</Button>
							</NavLink>
						</div>
					</div>
				</div>
			</div>
			<div>
				<Modal
					open={open}
					onClose={handleClose}
					aria-labelledby='modal-modal-title'
					aria-describedby='modal-modal-description'>
					<Box
						className='w-full md:w-1/2'
						sx={modalStyle}>
						<img
							src={headerData.image}
							alt='Nadia Cacciotti'
							style={{ width: "100%" }}
						/>
					</Box>
				</Modal>
			</div>
		</div>
	);
}

export default Landing;
