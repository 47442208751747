export const educationData = [
  {
    id: 1,
    institution: "",
    course:
      'Scrivi un messaggio WhatsApp al numero 351 7082473 con il tuo nome e cognome seguito dalla frase: "richiesta per la settimana omaggio". ',

    endYear: "PRIMO PASSAGGIO",
  },

  {
    id: 2,
    institution: "",
    course: "Attendi la risposta di conferma",
    startYear: "SECONDO PASSAGGIO",
  },
  // {
  //   id: 4,
  //   institution: "",
  //   course:
  //     "<<------- clicca sull'icona di whatsapp per aprire subito la chat con il numero 351 7082473",

  //   endYear: "OPPURE-",
  // },
  // {
  //   id: 4,
  //   institution: "University of Somerset",
  //   course: "Master of Technology",
  //   startYear: "-OPPURE",
  //   // endYear: "2021",
  // },
];
