import React, { useContext } from "react";
import "./Footer.css";
import { ThemeContext } from "../../contexts/ThemeContext";
import { headerData } from "../../data/headerData";

function Footer() {
	const shortname = (name) => {
		if (name.length > 10) {
			return name.split(" ")[0];
		} else {
			return name;
		}
	};

	const { theme } = useContext(ThemeContext);

	return (
		<footer class='flex flex-col items-center bg-neutral-100 text-center dark:bg-neutral-600 lg:text-left'>
			<div
				className='footer'
				style={{ backgroundColor: theme.secondary }}>
				<p style={{ color: theme.tertiary }}>
					Made with
					<span
						style={{ color: theme.primary, margin: "0 0.5rem -1rem 0.5rem" }}>
						❤
					</span>
					by {shortname(headerData.name)}
				</p>
			</div>
			<div class='w-full bg-neutral-200 p-4 text-center text-neutral-700 dark:bg-neutral-700 dark:text-neutral-200'>
				<p class='mb-4'>
					Nadia Cacciotti Dottoressa laureata in “Scienze dell’Alimentazione e
					Nutrizione Umana"
				</p>
				© 2021 Nama Srl unipersonale- Via Carlo Alberto 19 04100 Latina LT -
				Tel. 393.9631091 - Fax 0773.663597 - P.IVA: 03164740593
				<hr />
				<a
					class='text-neutral-800 dark:text-neutral-400 mr-4'
					href='https://www.iubenda.com/privacy-policy/37268001'>
					Privacy Policy
				</a>
				<a
					class='text-neutral-800 dark:text-neutral-400'
					href='https://www.iubenda.com/privacy-policy/37268001/cookie-policy'>
					Cookie Policy
				</a>
			</div>
		</footer>
	);
}

export default Footer;
