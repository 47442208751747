import { Box, Button, Stack } from "@mui/material";
import Card from "@mui/material/Card";
import CardActions from "@mui/material/CardActions";
import CardContent from "@mui/material/CardContent";
import CardHeader from "@mui/material/CardHeader";
import Container from "@mui/material/Container";
import Grid from "@mui/material/Grid";
import StarIcon from "@mui/icons-material/StarBorder";
import Typography from "@mui/material/Typography";
import { styled } from "@mui/system";
import { ThemeContext } from "../../contexts/ThemeContext";
import { useContext } from "react";
import { green } from "@mui/material/colors";
import { Chip } from "@material-ui/core";

const PricingList = styled("ul")({
  margin: 0,
  padding: 0,
  listStyle: "none",
});

const tier = {
  title: "PUOI SCEGLIERE TRA",
  price1: "35",
  price2: "175",
  price3: "315",
  description: [
    // "10 users included",
    // "2 GB of storage",
    // "Help center access",
    // "Email support",
  ],
  buttonText: "ISCRIVITI ORA",
  buttonVariant: "outlined",
};

export default function SinglePricer() {
  const { theme } = useContext(ThemeContext);
  return (
    <Container maxWidth="md" component="main">
      <Grid container spacing={0} justifyContent={"center"}>
        <Grid item>
          <Card>
            <CardHeader
              title={tier.title}
              subheader={tier.subheader}
              titleTypographyProps={{ align: "center" }}
              action={tier.title === "Pro" ? <StarIcon /> : null}
              subheaderTypographyProps={{
                align: "center",
              }}
              sx={{
                backgroundColor: theme.primary30,
              }}
            />
            <CardContent>
              <Box
                sx={{
                  display: "flex",
                  justifyContent: "center",
                  alignItems: "baseline",
                  mb: 2,
                }}
              >
                <Typography variant="h6" color="text.secondary"></Typography>
              </Box>
              <PricingList>
                <Typography component="h4" variant="h3" color="text.primary">
                  <Stack direction="row" spacing={1}>
                    <Chip label="1 MESE" color="success" variant="outlined" />
                    <Chip
                      label={`€ ${tier.price1}`}
                      color="secondary"
                      variant="outlined"
                    />
                  </Stack>
                </Typography>
                <br />
                <Typography component="h5" variant="h3" color="text.primary">
                  <Stack direction="row" spacing={1}>
                    <Chip label="6 MESI" color="red" variant="outlined" />
                    <Chip
                      label={`€ ${tier.price2}`}
                      color="secondary"
                      variant="outlined"
                    />
                    <Chip
                      label={`CON 1 MESE OMAGGIO`}
                      color="red"
                      variant="outlined"
                    />
                  </Stack>
                </Typography>
                <br />
                <Typography component="h4" variant="h3" color="text.primary">
                  <Stack direction="row" spacing={1}>
                    <Chip label="12 MESI" color="red" variant="outlined" />
                    <Chip
                      label={`€ ${tier.price3}`}
                      color="secondary"
                      variant="outlined"
                    />
                    <Chip
                      label={`CON 3 MESI OMAGGIO`}
                      color="red"
                      variant="outlined"
                    />
                  </Stack>
                </Typography>
              </PricingList>
            </CardContent>
            <CardActions>
              <div className="flex flex-col w-full gap-1">
                <Button
                  href="https://forms.gle/omXabpXjXZDwyiJr5"
                  fullWidth
                  variant={tier.buttonVariant}
                  sx={{
                    backgroundColor: green[500],
                    color: theme.secondary,
                    ":hover": { backgroundColor: green[700] },
                  }}
                >
                  {tier.buttonText}
                </Button>
                <Button
                  href="https://forms.gle/omXabpXjXZDwyiJr5"
                  fullWidth
                  variant={tier.buttonVariant}
                  sx={{
                    backgroundColor: green[500],
                    color: theme.secondary,
                    ":hover": { backgroundColor: green[700] },
                  }}
                  className="hidden"
                >
                  Rinnova
                </Button>
              </div>
            </CardActions>
          </Card>
        </Grid>
      </Grid>
    </Container>
  );
}
