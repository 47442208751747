/* eslint-disable */
import { BiShoppingBag, BiPencil } from "react-icons/bi";
import { BsCodeSlash, BsClipboardData } from "react-icons/bs";
import { AiOutlineMail, AiFillAudio } from "react-icons/ai";
import {
	FaInternetExplorer,
	FaChalkboardTeacher,
	FaCameraRetro,
	FaPinterest,
	FaVideo,
	FaTabletAlt,
	FaRegNewspaper,
} from "react-icons/fa";

import FacebookIcon from "@mui/icons-material/Facebook";
import OutdoorGrillIcon from "@mui/icons-material/OutdoorGrill";
import SelfImprovementIcon from "@mui/icons-material/SelfImprovement";
import DirectionsRunIcon from "@mui/icons-material/DirectionsRun";
import SpaIcon from "@mui/icons-material/Spa";
import MedicationLiquidIcon from "@mui/icons-material/MedicationLiquid";
import SportsGymnasticsIcon from "@mui/icons-material/SportsGymnastics";
import FitnessCenterIcon from "@mui/icons-material/FitnessCenter";
import PsychologyIcon from "@mui/icons-material/Psychology";

export const servicesData = [
	{
		id: 2,
		title: "ACCESSO AL GRUPPO FACEBOOK ",
		icon: <FacebookIcon />,
	},
	{
		id: 4,
		title: "LINEE GUIDA ALIMENTARI “MEDICHE”",
		icon: <MedicationLiquidIcon />,
	},
	{
		id: 11,
		title: "NATUROPATIA",
		icon: <SpaIcon />,
	},
	{
		id: 7,
		title: "YOGA",
		icon: <SelfImprovementIcon />,
	},
	{
		id: 5,
		title: "MOTIVAZIONE",
		icon: <FaChalkboardTeacher />,
	},
	{
		id: 12,
		title: "GINNASTICA",
		icon: <FitnessCenterIcon />,
	},
	{
		id: 13,
		title: "ZUMBA",
		icon: <SportsGymnasticsIcon />,
	},
	{
		id: 13,
		title: "MINDFULNESS",
		icon: <PsychologyIcon />,
	},
	{
		id: 8,
		title: "RICETTE DI CUCINA",
		icon: <OutdoorGrillIcon />,
	},
	{
		id: 6,
		title: "CAMMINATA IN COACHING",
		icon: <DirectionsRunIcon />,
	},
	// {
	// 	id: 1,
	// 	title: "NATUROPATA PERSONAL COACH",
	// 	icon: <SpaIcon />,
	// },

	// {
	// 	id: 3,
	// 	title: "PERCORSO DI NATUROPATIA",
	// 	icon: <SpaIcon />,
	// },

	// {
	// 	id: 9,
	// 	title: "BALLO",
	// 	icon: <FaRegNewspaper />,
	// },
	// {
	// 	id: 10,
	// 	title: "MEDITAZIONE",
	// 	icon: <AiFillAudio />,
	// },
];

// Uncomment your required service.
// Couldn't find the required services? Raise an issue on github at https://github.com/hhhrrrttt222111/developer-portfolio/issues/new
// You can also add on your own 😉.
