/* eslint-disable */
import girl1 from "../assets/svg/testimonials/girl1.svg";
import girl2 from "../assets/svg/testimonials/girl2.svg";
import girl3 from "../assets/svg/testimonials/girl3.svg";
// import boy1 from "../assets/svg/testimonials/boy1.svg";
// import boy2 from "../assets/svg/testimonials/boy2.svg";
// import boy3 from "../assets/svg/testimonials/boy3.svg";

export const testimonialsData = [
	{
		id: 1,
		name: "Marina",
		title: "",
		text: "Seguo Nadia da 15 mesi. È una donna eccezionale professionale  molto competente e preparata. Senza dieta ho perso peso senza stress. La cosa che mi ha fatto comprendere che bisogna imparare a mangiare sano senza diete. Da sempre sono stata a dieta  senza risultati evidenti. Ho sentito per la prima Volta  Nadia a dicembre del 2020 .All'inizio ho pensato sarà sempre la  stessa storia invece le sue dirette sono arrivate al mio cuore e alla mia testata. Ed eccomi oggi con autostima e con pensieri positivi..Grazie Nadia ti voglio bene",
		image: girl1,
	},
	{
		id: 2,
		name: "Giovanna",
		title: "",
		text: "Vorrei condividere il percorso che ho fatto con Nadia Cacciotti e il suo staff. Sono stata sempre rotondetta e le diete hanno accompagnato tutta la mia vita perché perdevo peso ma poi riprendevo il doppio dei chili persi. Con Nadia non ho fatto una dieta, ho imparato a conoscere il cibo sano e ho scoperto come funziona il mio corpo. Mangio il doppio  di prima ed ho perso ben 31 kg. Dopo circa un anno di alimentazione sana, avevo il terrore di riprendere i kg, invece sono 3 anni che il mio peso non cambia. Ho imparato le 5 regole fondamentali che non abbandonerò più e sono finalmente la persona che ho sempre sognato di essere. Ho superato momenti difficili seguendo le dirette su Facebook, Nadia è sempre al tuo fianco e ti da' la motivazione giusta per non mollare. Ho trovato il suo metodo di comunicazione...straordinario!!! Entrare a far parte di un gruppo che lotta per raggiungere lo stesso obbiettivo è stata un'esperienza che mi ha fatto riacquistare sicurezza e stima di me stessa. Non smettero' mai di ringraziarti cara Nadia!",
		image: girl2,
	},
	{
		id: 3,
		name: "Mara",
		title: "",
		text: "Ho iniziato questo meraviglioso percorso a luglio dello scorso anno, ho perso 9 chili ( non avevo molti chili da perdere) senza dieta senza pesare nulla, semplicemente seguendo un regime di alimentazione e di vita sano, perché è questo quello che ci insegna Nadia l'amore per noi stessi. Nadia è una professionista  seria preparata e disponibile, supportata da un team eccellente, la nostra Elisabetta sempre puntuale e attenta e tutti gli altri che fanno di questo gruppo di lavoro una vera eccellenza in questo settore. Far parte del gruppo condividere esperienze, dubbi ,emozioni, consigli è stato stimolante e di grande aiuto. Consiglio a tutti di affidarsi a Nadia e al suo team,  non ve ne pentirete. Grazie Nadia tornerò presto con voi",
		image: girl3,
	},
	{
		id: 4,
		name: "Andreina",
		title: "",
		text: "Nadia è una persona unica, speciale. Ama questo lavoro e prende a cuore ogni singola situazione. Da quando ho conosciuto lei la mia vita è cambiata. Mi sono iscritta alla prova omaggio per vedere se questa volta  se ce l'avrei fatta. Risultato? Ottimo! Quindi ho confermato la mia iscrizione. Con sé ha uno staff meraviglioso, non manca proprio niente. Abbiamo lei Nadia in primis (Naturopata) la nostra tutor Elisabetta, la nostra personal trainer Giuliana Mancini, la nostra Sabrina Cacciotti che si occupa di meditazione e il nostro chef Endrio che ci insegna con le sue ricette a cucinare sano. Gruppo spettacolare, unito e poi ci siamo tutte noi iscritte che ci confrontiamo e ci aiutiamo. In quaranta giorni, ho perso 7 kg e ne sono fiera. Una cosa che volevo evidenziare è che 'dimagrisco mangiando sano'. Grazie, e ancora grazie, a te Nadia e al tuo meraviglioso staff😘 Vi abbraccio tutti quanti. Una cosa è certa, rimarrò iscritta con voi. Siete tutti meravigliosi ❤️",
		image: girl1,
	},
	{
		id: 5,
		name: "Concita",
		title: "",
		text: "Ho iniziato a seguire Nadia sulla pagina ufficiale e poi ho chiesto la settimana di prova,e li mi si è aperto un mondo nuovo. Non solo sono dimagrita 17kg ma ho imparato a mangiare sano e farlo diventare uno stile di vita,ho imparato ad amarmi a fare meditazione ginnastica e a cucinare piatti gustosi. Non mi sono mai sentita a dieta e consiglio a tutti di seguirla. Grazie a Nadia e tutto il suo staff ,Elisabetta Giuliana Sabrina Endrio ❤️❤️",
		image: girl2,
	},
	{
		id: 6,
		name: "Daniela",
		title: "",
		text: "Nadia e il suo staff sono professionali formati informati e competenti tutti dal primo all'ultimo.Gestisce un gruppo su Facebook (cambio linea). Paghi pochissimo e hai a tua disposizione lezioni di movimento con persona laureata...e non un semplice istruttore, un tutor che ti segue in ogni tua esigenza sempre disponibile e carinissima,  meditazione, anche questa fatta con persona altamente competente, uno chef per le ricette salutarsi. E poi c'è Nadia che fa un lavoro bellissimo nel gruppo di motivazione, corsi sulla comunicazione e tanto altro, rubriche, e poi non vi dico altro andate a scoprirlo.....È un mondo meraviglioso quello di questo gruppo...ci entri per dimagrire e ti trovi cambiata, serena fiduciosa e super positiva.....W Cambio Linea  ",
		image: girl3,
	},
	{
		id: 7,
		name: "Luciana",
		title: "",
		text: "Ho conosciuto la naturopata Nadia Caciotti, per caso a dicembre 2021e subito mi ha incuriosito il suo metodo basato non su diete restrittive. Si è immediatamente mostrata come una donna che ti trasmette un’empatia enorme che, con le sue parole, ti trascina in un mondo fatto di informazioni sul mangiare sano e sui cibi che aiutano il corpo a stare in salute. Nadia è una donna preparata, sensibile, grintosa e disponibile con tutti.È sempre presente più volte alla settimana su Fb con i suoi preziosi consigli per aiutare le persone a cambiare il pensiero, sopprimere la rabbia, sapere perdonare, rimanere sempre sereni, motivando al cambiamento. Ci fa rafforzare la volontà con le sue parole,non si risparmia, ti puoi collegare con lei per avere consigli e uno scambio di idee.                        C’è anche da dire che l’impegno finanziario che ti chiede è proprio accessibile a tutti.Nel mio caso mi ha cambiato il pensiero rendendomi più sicura e mostrandomi gli aspetti positivi della vita e della sana alimentazione.Come ci dice Nadia “stiamo diventando dei piccoli Naturopati”e arrivando all’obiettivo potremo essere autonomi.Con Nadia collaborano Elisabetta, la nostra tutor, che è dimagrita 30 kg. Giuliana che ci attiva con l’attività fisica, Endrio ci dà consigli per una cucina molto sana. Infine Sabrina che attiva le sedute di mindfulness. Che dire?? Un meraviglioso staff.Cambio linea è veramente un’opportunità che vale la pena di conoscere per tanti che vogliono migliorata la loro vita e avere una sana alimentazione. Gra Vi voglio bene ❤️❤️💗💗💗",
		image: girl1,
	},
	{
		id: 8,
		name: "Annalisa",
		title: "",
		text: "Nadia è soprattutto una donna che con empatia sa mettere le proprie conoscenze al servizio degli altri. Ha competenza e preparazione che spaziano in vari ambiti e per questo il suo percorso verso il  dimagrimento, è un viaggio nel  benessere emotivo che porta poi ad un corretto approccio verso il cibo. Puoi anche dimagrire, ma se non cambi il modo di guardare al cibo, i tuoi risultati saranno sempre a rischio e soprattutto ti mancherà la stabilità. Nadia cammina al tuo fianco e non ti senti mai sola!",
		image: girl2,
	},
	{
		id: 9,
		name: "Silvia",
		title: "",
		text: "Sono incappata su fb una sera in una diretta sul gruppo aperto e rimasi colpita dalla determinazione e professionalità di questa donna....stavo attraversando un periodo un po brutto...non stavo bene con me stessa ero sempre nervosa avevo fatto le analisi e avevo il colesterolo altissimo in più non mi piacevo..mi vedevo grassa....così cominciai a seguirla fino a prendere la decisione di entrare nel suo gruppo...bè che dire....è stata la svolta della mia vita....seguirla mi ha dato modo di apprendere cose che non sapevo...avere più stima  e amor proprio per me stessa e devo dire che adesso a quasi un anno di distanza che sono nel suo gruppo ho raggiunto il mio obiettivo....sono una persona migliore sana e magra e alla mia età non è cosa da poco. Ho quasi 57 anni e devo dire che grazie a Nadia sono venti kg meno....sono sana e sono felice!!!",
		image: girl3,
	},
	{
		id: 10,
		name: "Antonia",
		title: "",
		text: "Ho conosciuto Nadia nel periodo del lockdow e ascoltandola tutti i giorni nella sua pagina ufficiale me ne sono innamorata perché con il suo modo gentile, la sua disponibilità, la sua professionalità mi ha dato fiducia. Così sono entrata a fare parte della grande famiglia di 'Cambio linea' e devo dire che i cambiamenti sono arrivati non solo fisici ma soprattutto mentale e l'autostima è migliorata tantissimo. Provare per credere perché 'Volere è potere' Lei ti porterà dove tu vuoi andare 💪Grazie Nadia ❤️",
		image: girl1,
	},
	{
		id: 11,
		name: "Donatella",
		title: "",
		text: "Nadia mi ha insegnato a mangiar bene e ad adottare uno stile di vita sano, per stare meglio fisicamente. E tutto ciò non lo vedo più come una restrizione, fatto solo per perdere peso, ma grazie a lei, dopo averla conosciuta a dicembre, vedo tutto ciò in un'altra ottica. È davvero preparatissima e spiega concetti, talvolta difficili, in modo davvero semplice e appassionante. Oltretutto è davvero una motivatrice ed è sempre super disponibile. Questo percorso mi sta aiutando tantissimo anche a livello mentale, dopo due anni per me difficili. Che altro dire?! Ah sì, avrei voluta conoscerla prima",
		image: girl2,
	},
	{
		id: 12,
		name: "Lidia",
		title: "",
		text: "Ho conosciuto Nadia Cacciotti x sbaglio girando su facebook. Ho seguito le sue dirette sul gruppo aperto ...poi mi sono detta ma xchè non provare la settimana omaggio è così è stato. Dopo la settimana omaggio sono rimasta nel gruppo dove c'è un meraviglioso staff a partire da Nadia Cacciotti, Elisabetta la tutor, Giuliana personal trainer che ci fa fare ginnastica, Sabrina che ci fa fare meditazione ed Endrio che ci dà sempre una nuova ricetta. Mi trovo bene e non potevo non trovare di meglio. Ho già perso dei Kili ma ne ho ancora da perdere, ma con l'aiuto di Nadia e le sue dirette motivazionali so che riuscirò. Grazie Nadia Cacciotti x averti incontrato nella mia vita.❤️❤️❤️",
		image: girl3,
	},
	{
		id: 13,
		name: "Daniela",
		title: "",
		text: "Ho iniziato questo percorso con Nadia naturopata due anni fa, come dice il proverbio provare per credere GRANDE DONNA, PROFESSIONALE AMA DAVVERO IL SUO LAVORO. Ho perso 16 kg senza fretta perché lei ti insegna mangiare sano e dissoci dalla mente quella parola orribile detta DIETA, quindi è stato un percorso non difficile e continuo  a proseguire senza sosta. Grazie Nadia per il percorso che ci insegni ad ognuno di noi. ❤",
		image: girl1,
	},
	{
		id: 14,
		name: "Patrizia",
		title: "",
		text: "Non ci sono sufficienti parole per descrivere la mia esperienza con Nadia e tutto il suo staff,sono in questo gruppo da sette e credo che sia su per me come trovare un tesoro, vincere alla lotteria. Lei è una vera professionista,mi sta tenendo per mano e mi sta aiutando a vincere contro me stessa, perché avevo perso la speranza di perdere peso,con lei ho capito che non conta solo perdere peso,conta stare bene, imparare a mangiare sano, essere sereni,abbandonare la rabbia,il dolore.imparare a perdonare.Lei fa tutto questo e lo fa con amore,dedizione incondizionata,lei è sempre disponibile, sempre gentile e amorevole. Non è una santa,no, è semplicemente una brava persona che ha fatto delle esperienze della sua vita, tantissimi frutti da donare.",
		image: girl2,
	},
	{
		id: 15,
		name: "Marta",
		title: "",
		text: "Nadia è una donna meravigliosa ed una professionista eccellente. Si spende per le sue pazienti in tutto e per tutto, con generosità, altruismo, non ti lascia sola mai nei momenti no, grazie anche al suo staff. Con lei non ti senti solamente una paziente, ti senti amata e compresa, sostenuta, non sei semplicemente un numero. Tiene a ciascuna di noi in maniera particolare, e ti trasmette una grande forza e motivazione.",
		image: girl3,
	},
	{
		id: 16,
		name: "Serena",
		title: "",
		text: "Dire Grazie è poco e pure banale! Ho conosciuto Nadia per caso parlando con un'amica e subito rimasi entusiasta nel sentire parlare così bene di lei e del suo meraviglioso staff. Ho voluto provare anch'io ad affidarmi a lei e per vedere se non erano solo chiacchere e devo confermare che tutto quello che avevo sentito dire era vero. Nadia è una persona preparata, buona, umile, grintosa e chi più ne ha ne metta! Ti insegna non solo a mangiare sano ma ad affrontare qualsiasi cosa con i suoi preziosi consigli. ti da la forza e la grinta che ti manca. Ha uno staff meraviglioso non ti senti sola. Io ora proverò ha camminare con le mie gambe ma non è detto che tornerò a bussare alla vostra porta.",
		image: girl1,
	},
	{
		id: 17,
		name: "Paola",
		title: "",
		text: "Non ho parole x definire questo gruppo , gestito da una grandissima Donna prima che Naturopata ed accompagnata da altrettanto stupende persone da Elisabetta che ci segue tutti i giorni e risponde a tutte le ns domande , ad Endriu che ci inonda di tante e sfiziose ricette, a Sabrina grazie alle sue lezioni motivazionali e a Giuliana la ns. coach che ci segue in base alle ns esigenze con lezioni di corso base ed avanzato quasi tutti i giorni . In gruppo fatto di persone che grazie a tutto questo e all amore che ci mette Nadia x il suo lavoro permette di intraprendere un percorso non di dimagrimento ma di cambiamento x adeguamento ad uno stile di vita sano sia fisico che mentale.  E difficile da spiegare credo che l unico modo per capirlo e provare a fare questa settimana di prova che vi assicuro Vi darà nuove spinte  x mettere sempre al primo posto noi stesse...Un abbraccio a tutto lo staff ❤",
		image: girl2,
	},
	{
		id: 18,
		name: "Loredana",
		title: "",
		text: "Grande coach motivazionale che ti ricarica di energia positiva. Personalmente entrando nel suo gruppo segreto di Facebook ho perso 7 kg in due mesi senza una dieta dimagrante. Sto facendo allenamento fisico, seguo le ricette di Endrio il cuoco di ricette sane ed equilibrate e soprattutto seguo ogni g le dirette di Nadia. In questo periodo sono focalizzata sul mio atteggiamento positivo. “L’atteggiamento che assumiamo ad ogni compito determina come la vita si muove verso di me”…”La nostra mente può avere un solo pensiero alla volta…Se qualcuno ci tratta male è perché noi glielo permettiamo…” BENESSERE AUTOCONTROLLO!!! Provate e mi darete ragione nessuno mi ha pagata per fare questa recensione è la pura verità.",
		image: girl3,
	},
	{
		id: 19,
		name: "Grazia",
		title: "",
		text: "Grande professionista di una squisita umiltà e bontà.Seguendo i suoi consigli ho perso parecchi chili senza faticare. Con lei non sono stata mai a dieta ma ho imparato ad apprezzare pietanze più salutari e cibi che io non utilizzavo. Insieme a lei collaborano  persone molto preparate . È stato un bellissimo viaggio dove ho imparato molte cose . Grazie a tutti loro in particolare alla Naturopata Nadia",
		image: girl1,
	},
	{
		id: 20,
		name: "Silvia",
		title: "",
		text: "Seguo la dottoressa Nadia da pochi mesi e mi trovo benissimo. La sua professionalità è completa. Non solo cura l'aspetto dell'alimentazione, ma anche quello motivazionale che io non ho mai trovato utilizzato dai dietologi e nutrizionisti che ho conosciuto e che invece può essere decisivo per raggiungere il risultato finale. Mi piace anche l'approccio molto umano che ha nei confronti di chi la segue e la sua continua disponibilità per chi la contatta. Tanti complimenti alla dottoressa.👏❤",
		image: girl2,
	},
	{
		id: 21,
		name: "Concetta",
		title: "",
		text: "Una donna eccezionale!! Molto preparata nel suo campo!! Ha studiato tantissimo e si vede!!! Ragazzi con lei ho perso 10 kg in 4 mesi.. cosa che con la dietologa non scendevo così rapidamente e poi dovevo pesare tutto!!  No, con Nadia non peso niente ed ho imparato a mangiare sano dimagrendo! Non potevo crederci!! Grazie mille Nadia e a tutto il tuo staff scelto con cura!!! ❤❤❤😘😍 ci risentiremo presto!! Tvb onny",
		image: girl3,
	},
	{
		id: 22,
		name: "Ivana",
		title: "",
		text: "Ho incontrato Nadia Cacciotti per caso su Fb in una diretta di motivazione e ne sono rimasta entusiasta, dopo la prima ho continuato a seguire le sue dirette che mi facevano riflettere sul mio atteggiamento nei riguardi del cibo. È la prima volta che mi sento così determinata a continuare nella strada indicata da Nadia , in salute. Grazie",
		image: girl1,
	},
	{
		id: 23,
		name: "Lala",
		title: "",
		text: "Il percorso con Nadia. È stato fantastico ho imparato tantissime cose la gestione degli alimenti le giuste combinazioni e tanto altro Ringrazio Nadia per la sua professionalità umiltà sempre pronta a risolvere tutti i dubbi le cadute ecc ed è qualcosa che non si trova facilmente lo straconsiglio a tutti coloro che vogliono un cambiamento sano vero e duraturo senza sacrifici imparando veramente a mangiar sano Ringrazio lo staff di Nadia che  ci sostiene sotto ogni aspetto durante il percorso dalle ricette alla ginnastica alle motivazioni al rispondere ad ogni nostro quesito sono riuscita a raggiungere il mio obiettivo mi sono divertita e sicuramente rientrerò nuovamente, anche se non ho bisogno, nel percorso perché ci sono sempre. Cose da imparare Da Nadia e la sua family Consiglio a tutti di provare sarete sicuramente soddisfatti e riuscirete a raggiungere i vostri obiettivi",
		image: girl2,
	},
	{
		id: 24,
		name: "Claudia",
		title: "",
		text: "Unica, instancabile.. Disponibile.. quando mi sono sentita pronta, mi sono iscritta al suo gruppo segreto... È lì e scoppiata in me una energia nuova a 66 anni.. Non sono solo dimagrita, ma ho buttato via zavorre mentali.. E sono tornata leggera nel corpo e nella mente!!  (- 5 kg in 3 mesi) Raggiunto il primo obiettivo fissato, ora si procede verso la seconda meta!! Grazie Nadia e tutto il team",
		image: girl3,
	},
	{
		id: 25,
		name: "Valentina",
		title: "",
		text: "Da quando ho conosciuto Nadia,ho iniziato veramente a prendermi cura di me. Oltre l'alimentazione,ci sprona a buttar fuori il potenziale che è in noi. Naturopata è dire poco...oltre la sua professionalità che ti porta a migliorare a livello fisico,ma soprattutto è un percorso crescita personale. Sceglierei e mi affiderei a lei sempre...peccato non averla conosciuta prima.",
		image: girl1,
	},
	{
		id: 26,
		name: "Patrizia",
		title: "",
		text: "Conosco Nadia da Agosto e piu' vado avanti e piu' mi rendo conto che oltre ad essere una donna con la D maiuscola, e' un vortice di energia che ti cattura e ti coinvolge sempre piu'. Sensibile, umana e soprattutto professionale. Non ha maschere , sembra di avere a che fare con nostra sorella, non giudica ne' disprezza , ma ti incoraggia e ti sostiene. Sa fugare dubbi e paure ,  senza nessun tipo di restrizione o diete  si perde peso, grazie al suo metodo di alimentazione sana, equilibrata e soprattutto salutare. Non posso piu' tornare ad alimentarmi in maniera disordinata come prima. Grazie Nadia Cacciotti Naturopata",
		image: girl2,
	},
	{
		id: 27,
		name: "Roberta",
		title: "",
		text: "Nadia non sembra nemmeno terrena, è magica. Parla in modo comprensibile e quello che dice ti rimane in testa. La sua formula è 'cambiare per sempre' ed è quello che si deve fare per invertire la tendenza. È umana, solare ed onesta . Non fa false promesse perché sa che l'unico modo per ottenere risultati è cambiare la testa , lei ti accompagna e supporta sempre. Propone un programma accessibile a tutti e senza speculare. Se volete cambiare, veramente , Nadia è la persona giusta!",
		image: girl3,
	},
	{
		id: 28,
		name: "Fiorella",
		title: "",
		text: "Ho impiegato anni per capire che il problema, nel sovrappeso importante, non è il cibo, ma il rapporto che si ha con il cibo. Nadia Cacciotti propone un efficace approccio al cambiamento, operando con professionalità a 360°, ottendo così risultati duraturi nel tempo e definitivi: attraverso consigli alimentari, informando sui principi e sulle giuste combinazioni dei cibi per arrivare finalmente ad una alimentazione sana, secondo la naturopatia; avvalendosi di collaboratori come trainer, naturalchef, mindfullness; efficace coach motivazionale è presente in tutte le nostre giornate insieme ai suoi collaboratori. E finalmente,  noi obesi che siamo 'polli da spennare' abbiamo un aiuto mensile che ci costa meno che un massaggio di 35 minuti",
		image: girl1,
	},
	{
		id: 29,
		name: "Ilaria",
		title: "",
		text: "Ho iniziato il mio percorso con Nadia a gennaio, consiglierei a tutti il suo programma, hai tutto, dalla ginnastica, alle ricette, alla meditazione e 1 volta la settimana la linea guida alimentare da seguire, UNA VERA FAMIGLIA!! Se hai bisogno di supporto, loro ci sono sempre, che dire, in ottima esperienza.",
		image: girl2,
	},
	{
		id: 30,
		name: "Vera",
		title: "",
		text: "Ho iniziato questo percorso di vita (perché per me è questo) ad inizio marzo. Nadia mi ha insegnato cosa sia gestire le proprie emozioni e soprattutto diventare tu il capo di queste. Nadia ha una forza ed in energia che puoi capire solo se segui passo passo ogni sua parola e motivazione. Sto imparando ogni giorno a prendermi cura di me stessa ,sia mentalmente che fisicamente. Con lei fino adesso ho perso 9 kili ma questi passano in secondo piano se li paragono al lavaggio del cervello (in positivo) che mi ha fatto. Ormai la mia frase motivazionale è questa: Cambia mente -Cambia corpo. Tutti dovrebbero seguire Nadia ,Elisabetta e tutto il loro meraviglioso team perchè quando riescono a cambiare la mente e il pensiero …sei sul percorso giusto per il cambiamento.",
		image: girl3,
	},
];
