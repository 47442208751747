import React, { useContext, useRef, useState } from "react";
import { Snackbar, IconButton, SnackbarContent } from "@material-ui/core";
import CloseIcon from "@material-ui/icons/Close";

import isEmail from "validator/lib/isEmail";
import { makeStyles } from "@material-ui/core/styles";
import {
	FaTwitter,
	// FaLinkedinIn,
	// FaGithub,
	FaYoutube,
	// FaBloggerB,
	// FaRedditAlien,
	// FaStackOverflow,
	// FaCodepen,
	FaInstagram,
	// FaGitlab,
	// FaMediumM,
	FaFacebook,
	FaTiktok,
	FaTelegram,
} from "react-icons/fa";
import { AiOutlineSend, AiOutlineCheckCircle } from "react-icons/ai";
import { FiPhone, FiAtSign } from "react-icons/fi";
import { HiOutlineLocationMarker } from "react-icons/hi";

import { ThemeContext } from "../../contexts/ThemeContext";

import { socialsData } from "../../data/socialsData";
import { contactsData } from "../../data/contactsData";
import "./Contacts.css";

import emailjs from "@emailjs/browser";

function Contacts() {
	const [open, setOpen] = useState(false);

	const [name, setName] = useState("");
	const [email, setEmail] = useState("");
	const [message, setMessage] = useState("");

	const [success, setSuccess] = useState(false);
	const [errMsg, setErrMsg] = useState("");

	const { theme } = useContext(ThemeContext);
	const form = useRef();

	const handleClose = (event, reason) => {
		if (reason === "clickaway") {
			return;
		}

		setOpen(false);
	};

	const useStyles = makeStyles((t) => ({
		input: {
			border: `4px solid ${theme.primary80}`,
			backgroundColor: `${theme.secondary}`,
			color: `${theme.tertiary}`,
			fontFamily: "var(--primaryFont)",
			fontWeight: 500,
			transition: "border 0.2s ease-in-out",
			"&:focus": {
				border: `4px solid ${theme.primary600}`,
			},
		},
		message: {
			border: `4px solid ${theme.primary80}`,
			backgroundColor: `${theme.secondary}`,
			color: `${theme.tertiary}`,
			fontFamily: "var(--primaryFont)",
			fontWeight: 500,
			transition: "border 0.2s ease-in-out",
			"&:focus": {
				border: `4px solid ${theme.primary600}`,
			},
		},
		label: {
			backgroundColor: `${theme.secondary}`,
			color: `${theme.primary}`,
			fontFamily: "var(--primaryFont)",
			fontWeight: 600,
			fontSize: "0.9rem",
			padding: "0 5px",
			transform: "translate(25px,50%)",
			display: "inline-flex",
		},
		socialIcon: {
			width: "45px",
			height: "45px",
			borderRadius: "50%",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			fontSize: "21px",
			backgroundColor: theme.primary,
			color: theme.secondary,
			transition: "250ms ease-in-out",
			"&:hover": {
				transform: "scale(1.1)",
				color: theme.secondary,
				backgroundColor: theme.tertiary,
			},
		},
		detailsIcon: {
			backgroundColor: theme.primary,
			color: theme.secondary,
			borderRadius: "50%",
			width: "45px",
			height: "45px",
			display: "flex",
			alignItems: "center",
			justifyContent: "center",
			fontSize: "23px",
			transition: "250ms ease-in-out",
			flexShrink: 0,
			"&:hover": {
				transform: "scale(1.1)",
				color: theme.secondary,
				backgroundColor: theme.tertiary,
			},
		},
		submitBtn: {
			backgroundColor: theme.primary,
			color: theme.secondary,
			transition: "250ms ease-in-out",
			"&:hover": {
				transform: "scale(1.08)",
				color: theme.secondary,
				backgroundColor: theme.tertiary,
			},
		},
	}));

	const classes = useStyles();

	const handleContactForm = (e) => {
		e.preventDefault();

		if (name && email && message) {
			if (isEmail(email)) {
				// const responseData = {
				// 	name: name,
				// 	email: email,
				// 	message: message,
				// };
				setSuccess(true);
				emailjs
					.sendForm(
						"service_vgs4sc2", //----- service ID
						"template_qxly8v8",
						form.current,
						"_tfAwx53wf-DcAGLE",
					)
					.then(
						(result) => {
							console.log(result.text);
							setErrMsg("Messaggio inviato con successo");
							setTimeout(() => {
								setOpen(true);
								setSuccess(false);
								setName("");
								setEmail("");
								setMessage("");
							}, 1000);
						},
						(error) => {
							console.log(error.text);
						},
					);
			} else {
				setErrMsg("L'indirizzo mail non è valido");
				setOpen(true);
			}
		} else {
			setErrMsg("Tutti i campi devono essere compilati");
			setOpen(true);
		}
	};

	return (
		<div
			className='contacts'
			id='contacts'
			style={{ backgroundColor: theme.secondary }}>
			<div className='contacts--container'>
				<h1 style={{ color: theme.primary }}>Invia un messaggio a Nadia</h1>
				<div className='contacts-body'>
					<div className='contacts-form'>
						<form
							ref={form}
							onSubmit={handleContactForm}>
							<div className='input-container'>
								<label
									htmlFor='user_name'
									className={classes.label}>
									Nome
								</label>
								<input
									placeholder='Inserisci il  tuo nome'
									value={name}
									onChange={(e) => setName(e.target.value)}
									type='text'
									name='user_name'
									className={`form-input ${classes.input}`}
								/>
							</div>
							<div className='input-container'>
								<label
									htmlFor='user_email'
									className={classes.label}>
									E-mail
								</label>
								<input
									placeholder='inserisci la tua e-mail'
									value={email}
									onChange={(e) => setEmail(e.target.value)}
									type='email'
									name='user_email'
									className={`form-input ${classes.input}`}
								/>
							</div>
							<div className='input-container'>
								<label
									htmlFor='message'
									className={classes.label}>
									Messaggio
								</label>
								<textarea
									placeholder='Scrivi il tuio messaggio ...'
									value={message}
									onChange={(e) => setMessage(e.target.value)}
									type='text'
									name='message'
									className={`form-message ${classes.message}`}
								/>
							</div>

							<div className='submit-btn'>
								<button
									type='submit'
									className={classes.submitBtn}>
									<p>{!success ? "Invia" : "Inviato"}</p>
									<div className='submit-icon'>
										<AiOutlineSend
											className='send-icon'
											style={{
												animation: !success
													? "initial"
													: "fly 0.8s linear both",
												position: success ? "absolute" : "initial",
											}}
										/>
										<AiOutlineCheckCircle
											className='success-icon'
											style={{
												display: !success ? "none" : "inline-flex",
												opacity: !success ? "0" : "1",
											}}
										/>
									</div>
								</button>
							</div>
						</form>
						<Snackbar
							anchorOrigin={{
								vertical: "top",
								horizontal: "center",
							}}
							open={open}
							autoHideDuration={4000}
							onClose={handleClose}>
							<SnackbarContent
								action={
									<React.Fragment>
										<IconButton
											size='small'
											aria-label='close'
											color='inherit'
											onClick={handleClose}>
											<CloseIcon fontSize='small' />
										</IconButton>
									</React.Fragment>
								}
								style={{
									backgroundColor: theme.primary,
									color: theme.secondary,
									fontFamily: "var(--primaryFont)",
								}}
								message={errMsg}
							/>
						</Snackbar>
					</div>

					<div className='contacts-details'>
						<a
							href={`mailto:${contactsData.email}`}
							className='personal-details'>
							<div className={classes.detailsIcon}>
								<FiAtSign />
							</div>
							<p
								style={{
									color: theme.tertiary,
									fontSize: "1rem",
									width: "100%",
								}}>
								{contactsData.email}
							</p>
						</a>
						<a
							href={`tel:${contactsData.phone}`}
							className='personal-details'>
							<div className={classes.detailsIcon}>
								<FiPhone />
							</div>
							<p style={{ color: theme.tertiary, fontSize: "1rem" }}>
								{contactsData.phone}
							</p>
						</a>
						<div className='personal-details'>
							<div className={classes.detailsIcon}>
								<HiOutlineLocationMarker />
							</div>
							<p style={{ color: theme.tertiary, fontSize: "1rem" }}>
								{contactsData.address}
							</p>
						</div>

						<div className='socialmedia-icons'>
							{socialsData.telegram && (
								<a
									href={socialsData.telegram}
									target='_blank'
									rel='noreferrer'
									className={classes.socialIcon}>
									<FaTelegram aria-label='Telegram' />
								</a>
							)}
							{socialsData.twitter && (
								<a
									href={socialsData.twitter}
									target='_blank'
									rel='noreferrer'
									className={classes.socialIcon}>
									<FaTwitter aria-label='Twitter' />
								</a>
							)}

							{socialsData.tikTok && (
								<a
									href={socialsData.tikTok}
									target='_blank'
									rel='noreferrer'
									className={classes.socialIcon}>
									<FaTiktok aria-label='tikTok' />
								</a>
							)}
							{socialsData.instagram && (
								<a
									href={socialsData.instagram}
									target='_blank'
									rel='noreferrer'
									className={classes.socialIcon}>
									<FaInstagram aria-label='Instagram' />
								</a>
							)}
							{socialsData.facebook && (
								<a
									href={socialsData.facebook}
									target='_blank'
									rel='noreferrer'
									className={classes.socialIcon}>
									<FaFacebook aria-label='Facebook' />
								</a>
							)}

							{socialsData.youtube && (
								<a
									href={socialsData.youtube}
									target='_blank'
									rel='noreferrer'
									className={classes.socialIcon}>
									<FaYoutube aria-label='YouTube' />
								</a>
							)}
						</div>
					</div>
				</div>
			</div>
			<img
				src={theme.contactsimg}
				alt='contacts'
				className='contacts--img'
			/>
		</div>
	);
}

export default Contacts;
